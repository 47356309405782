import DecideImage from "../assets/misc/thinkingman.png";
import {useState, useEffect } from 'react';

function Pitch() {

  const [mobile, setMobile] = useState(false);

  const [mobileTwo, setMobileTwo] = useState(false);


  function handleResize() {
    if (window.innerWidth < 1000) setMobile(true);
    else setMobile(false);


    if (window.innerWidth < 660) setMobileTwo(true);
    else setMobileTwo(false);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  });


  return (
    <div style={{position: 'relative', width: mobile ? "80vw" : "50vw", marginLeft: mobile? "10vw" : "25vw", minHeight: 460}}> 
    <img style={{ width: !mobileTwo ? 162 : 162/1.5, height: !mobileTwo ? 426 : 426/1.5, position: "absolute", zIndex: -1, left: 0, top: 0 }}
            src={DecideImage} alt="decide where to advertise with confidence"/>
        <div style={{marginLeft: !mobileTwo ? 178 : 178/1.5}}>
          <h1 style={{ fontSize: "1.6rem", width: "90%", textAlign: 'left'}}>
            HOW DO YOU DECIDE WHERE TO ADVERTISE?
          </h1>
          <div style={{ height: 16 }}></div>
          <p style={{ textAlign: "justify" }}>
            Many companies choose their advertisement platform based on
            intuition. A B2B business might focus on Google and LinkedIn, while
            a shoe company could decide to focus their efforts on Facebook and
            Instagram. But do you truly know that your product wouldn’t perform
            better on Tiktok, snapchat or even through Google AdMob in a mobile
            game?
          </p>
          <div style={{ height: 16 }}></div>
          <p style={{ textAlign: "justify" }}>
            With adbinder you can test out your ads on a number of different
            platforms, find out where it performs best, and adjust accordingly.
            You can also import currently active campaigns and spread them out
            across the other services.
          </p>
        </div>
    </div>
  );
}

export default Pitch;
