import { Grid, Link } from "@material-ui/core";
//import Twitter from '../assets/icons/icons8-twitter.svg';
import LinkedIn from "../assets/icons/icons8-linkedin.svg";
import Facebook from "../assets/icons/icons8-facebook.svg";
import Instagram from "../assets/icons/icons8-instagram-logo.svg";
import { useState, useEffect } from "react"

function Footer() {

  const [mobile, setMobile] = useState(false);

  function handleResize() {
    if (window.innerWidth < 960) setMobile(true);
    else setMobile(false);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  });

  return (
    <div
      style={{
        backgroundColor: "#000",
        color: "#fff",
        paddingTop: 64,
        paddingBottom: 32,
      }}
    >
      <div style={{ maxWidth: "50vw", marginLeft: "25vw" }}>
        <Grid container direction="row" alignContent="space-between">
          <Grid item sm={12} md={8}>
            <Grid container direction="column" style={{ textAlign: mobile ? "center" : "left" }}>
              <p style={{ fontWeight: "bold" }}>adbinder.com</p>
              <p>Vigerslev allé 68, 2500 Valby</p>
              <p>Copenhagen, Denmark</p>
              <p>VAT: DK40351981</p>
              <p>info@adbinder.com</p>
              <p>+45 23 25 61 91</p>
              {mobile && <div style={{height: 32}}></div>}
            </Grid>
          </Grid>
          <Grid item sm={12} md={4}>
            <Grid container direction="column">
              <Grid item>
                {/*<img src={Twitter} alt="twitter link" style={{width: 48, height: 48}}></img>*/}
                <Link
                  href="https://www.instagram.com/adbinderapp/"
                  target="_blank"
                >
                  <img
                    src={Instagram}
                    alt="instagram link"
                    style={{ width: 48, height: 48 }}
                  ></img>
                </Link>

                <Link
                  href="https://www.facebook.com/adbinderapp/"
                  target="_blank"
                >
                  <img
                    src={Facebook}
                    alt="facebook link"
                    style={{ width: 48, height: 48 }}
                  ></img>
                </Link>
                <Link
                  href="https://www.linkedin.com/company/adbinder/"
                  target="_blank"
                >
                  <img
                    src={LinkedIn}
                    alt="linkedin link"
                    style={{ width: 48, height: 48 }}
                  ></img>
                </Link>
              </Grid>
              <Grid>
                <Link href="https://adbinder.com/privacy-policy/">
                  <p style={{ color: "#fff" }}>privacy policy</p>
                </Link>
                <Link href="https://adbinder.com/terms-of-service/">
                  <p style={{ color: "#fff" }}>terms of service</p>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Footer;
