import { Grid } from "@material-ui/core";
import StartTrialButton from "../components/starttrialbutton";

function Pricing() {
  return (
    <div
      id="pricing"
      style={{
        backgroundColor: "#fafafa",
        paddingTop: 128,
        paddingBottom: 128,
      }}
    >
      <h1 style={{ fontSize: "1.6rem" }}>PRICING</h1>
      <div style={{ height: 16 }}></div>
      <h1
        style={{
          fontSize: "1rem",
          color: "#696969",
          marginLeft: 16,
          marginRight: 16,
        }}
      >
        ALL PLANS COME WITH A FREE 30-DAY TRIAL
      </h1>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        style={{
          width: "80vw",
          marginLeft: "10vw",
        }}
      >
        <Grid
          md={3}
          sm={12}
          item
          style={{
            backgroundColor: "#fff",
            padding: 24,
            height: 450,
            width: "100%",
            borderRadius: 16,
            margin: 16,
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{
              height: 400,
            }}
          >
            <Grid item>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "left",
                  color: "#83eaf1",
                }}
              >
                STARTUP
              </p>
              <div style={{ height: 16 }}></div>
              <p
                style={{
                  fontWeight: 400,
                  color: "#bebebe",
                  fontSize: "1rem",
                  textAlign: "left",
                }}
              >
                Up to 2 seats
                <br />
                <br />
                Up to 20 ads/month
                <br />
                <br />
                Role-based access management
              </p>
            </Grid>

            <Grid item>
              <StartTrialButton />
              <div style={{ height: 16 }}></div>
              <p
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                FREE
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          md={3}
          sm={12}
          item
          style={{
            backgroundColor: "#fff",
            padding: 24,
            height: 450,
            width: "100%",
            borderRadius: 16,
            margin: 16,
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{
              height: 400,
            }}
          >
            <Grid item>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "left",
                  color: "#469bfe",
                }}
              >
                SCALEUP
              </p>
              <div style={{ height: 16 }}></div>
              <p
                style={{
                  fontWeight: 400,
                  color: "#bebebe",
                  fontSize: "1rem",
                  textAlign: "left",
                }}
              >
                free 30-day trial
                <br />
                <br />
                Up to 8 seats
                <br />
                <br />
                Up to 50 ads/month
                <br />
                <br />
                Role-based access management
                <br />
                <br />
                Manage several organizations
              </p>
            </Grid>
            <Grid item>
              <StartTrialButton />
              <div style={{ height: 16 }}></div>
              <p
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                $199/MONTH
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          md={3}
          sm={12}
          item
          style={{
            backgroundColor: "#fff",
            padding: 24,
            height: 450,
            width: "100%",
            borderRadius: 16,
            margin: 16,
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{
              height: 400,
            }}
          >
            <Grid item>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "left",
                  color: "#045de9",
                }}
              >
                PRO
              </p>
              <div style={{ height: 16 }}></div>
              <p
                style={{
                  fontWeight: 400,
                  color: "#bebebe",
                  fontSize: "1rem",
                  textAlign: "left",
                }}
              >
                free 30-day trial
                <br />
                <br />
                Up to 20 seats
                <br />
                <br />
                Up to 100 ads/month
                <br />
                <br />
                Role-based access management
                <br />
                <br />
                Manage several organizations
                <br />
                <br />
                24/7 support
              </p>
            </Grid>
            <Grid item>
              <StartTrialButton />
              <div style={{ height: 16 }}></div>
              <p
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                $499/MONTH
              </p>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid md={3} sm={12} item style={{
              backgroundColor: "#fff",
              padding: 24,
              height: 450,
              width: "100%",
              borderRadius: 16,
              margin: 16
            }}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{
              height: 400}}
          >
            <Grid item>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "left",
                  color: "#293b8b",
                }}
              >
                ENTERPRISE
              </p>
              <div style={{ height: 16 }}></div>
              <p
                style={{
                  fontWeight: 400,
                  color: "#bebebe",
                  fontSize: "1rem",
                  textAlign: "left",
                }}
              >
                free 30-day trial
                <br />
                <br />
                Unlimited seats
                <br />
                <br />
                Unlimited ads/month
                <br />
                <br />
                Role-based access management
                <br />
                <br />
                Manage several organizations
                <br />
                <br />
                Dedicated customer success engineer
                <br />
                <br />
                24/7 support
              </p>
            </Grid>
            <Grid item>
              <Scroll.Link to="contact" spy={true} smooth={true} duration={500}>
                <Link style={{ cursor: "pointer", color: "#000" }}>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    CONTACT US
                  </p>
                </Link>
              </Scroll.Link>
            </Grid>
          </Grid>
        </Grid>*/}
      </Grid>
    </div>
  );
}

export default Pricing;
