import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Link } from "@material-ui/core";
// TODO: Sørg for at det bliver logget hver gang nogen trykker på denne her knap
// TODO: Send folk til en google forms? eller lave en form selv måske?

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(-45deg, #4a5eea 30%, #469bfe 90%)",
    border: 0,
    borderRadius: 50,
    color: "white",
    height: 42,
    padding: "0 20px",
  },
});

const StartTrialButton = () => (
  <Link
    href="https://adbinder.com/select-plan/"
    style={{
      textDecoration: "none",
    }}
  >
    <Button color="primary" variant="contained" className={useStyles().root}>
      GET STARTED FOR FREE
    </Button>
  </Link>
);

export default StartTrialButton;
