import Contact from "../screens/contact";
import Hero from "../screens/hero";
import Pitch from "../screens/pitch";
import Pricing from "../screens/pricing";
import Services from "../screens/services";
import YourData from "../screens/yourdata";

const Home = () => {
  return (
    <div>
      <Hero />
      <Services />
      <div style={{ height: 128 }}></div>
      <Pitch />
      <div style={{ height: 64 }}></div>
      <YourData />
      <div style={{ height: 64 }}></div>
      <div style={{ height: 128 }}></div>
      <Pricing />
      <div style={{ height: 128 }} id="contact"></div>
      <Contact />
      <div style={{ height: 128 }}></div>
    </div>
  );
};

export default Home;
