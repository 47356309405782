import { Link } from "@material-ui/core";

export default function PrivacyPolicy() {
  return (
    <div style={{ margin: "auto", padding: 64 }}>
      <h1>privacy policy</h1>
      <div style={{ height: 16 }}></div>
      <p>Coming soon...</p>
      <div style={{ height: 16 }}></div>
      <Link href="https://adbinder.com">Back to front page</Link>
    </div>
  );
}
