import { useEffect, useState } from "react";
import { analytics, auth, db } from "../firebase/firebaseConfig";
import { Checkbox, TextField, Grid, FormControlLabel } from "@material-ui/core";
import DefaultButton from "../components/defaultbutton";

export default function ComingSoonOne() {
  useEffect(() => {
    analytics.logEvent("coming-soon-1-free");

    auth
      .signInAnonymously()
      .then((x) => {
        db.collection("landingData")
          .doc(x.user?.uid)
          .set(
            {
              comingsoon1: true,
              dateNow: Date.now(),
              dateNowLocale: new Date().toLocaleString("en-DK"),
              anymousUserUid: x.user?.uid,
            },
            { merge: true }
          );
      })
      .catch((x) => {
        db.collection("landingData").add({
          comingsoon1: true,
          dateNow: Date.now(),
          dateNowLocale: new Date().toLocaleString("en-DK"),
          anymousUserUid: "ERROR HAPPENED",
          error: x.toString(),
        });
      });
  }, []);

  function saveInFirebase(
    beta: boolean,
    notify: boolean,
    newsletter: boolean,
    name: string,
    email: string,
    company: string
  ) {
    auth
      .signInAnonymously()
      .then((x) => {
        db.collection("landingNewsletter").add({
          comingsoon1: true,
          beta: beta,
          notify: notify,
          newsletter: newsletter,
          name: name,
          email: email,
          company: company,
          dateNow: Date.now(),
          dateNowLocale: new Date().toLocaleString("en-DK"),
          anymousUserUid: x.user?.uid,
        });
      })
      .catch((x) => {
        db.collection("landingNewsletter").add({
          comingsoon1: true,
          beta: beta,
          notify: notify,
          newsletter: newsletter,
          name: name,
          email: email,
          company: company,
          dateNow: Date.now(),
          dateNowLocale: new Date().toLocaleString("en-DK"),
          anymousUserUid: "ERROR HAPPENED",
          error: x.toString(),
        });
      });
  }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [beta, setBeta] = useState(false);
  const [notify, setNotify] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [sent, setSent] = useState(false);

  return (
    <div
      style={{ maxWidth: 600, margin: "auto", marginTop: 64, marginBottom: 64 }}
    >
      <h4> You caught us before we were ready </h4>
      <div style={{ height: 32 }}></div>
      <p style={{ textAlign: "justify" }}>
        Thank you for your interest in adbinder. We are currently putting the
        finishing touches on the platform, and the platform is currently in
        closed beta. If you are interested in trying adbinder as soon as
        possible, please fill in your info below.
      </p>
      <Grid
        container
        direction="column"
        style={{ marginTop: 32, marginBottom: 16 }}
      >
        {!sent && (
          <TextField
            label="Name (required)"
            fullWidth={true}
            InputLabelProps={{ shrink: true }}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        )}
        {!sent && <div style={{ height: 16 }}></div>}
        {!sent && (
          <TextField
            label="Email (required)"
            fullWidth={true}
            InputLabelProps={{ shrink: true }}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        )}
        {!sent && <div style={{ height: 16 }}></div>}
        {!sent && (
          <TextField
            label="Company name (optional)"
            fullWidth={true}
            InputLabelProps={{ shrink: true }}
            value={company}
            onChange={(e) => {
              setCompany(e.target.value);
            }}
          />
        )}

        {!sent && <div style={{ height: 16 }}></div>}
        {!sent && (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={beta}
                onChange={(e) => {
                  setBeta(e.target.checked);
                }}
              />
            }
            label="I would like to receive an invite for the closed beta test."
            labelPlacement="end"
            value={beta}
          />
        )}
        {!sent && <div style={{ height: 16 }}></div>}
        {!sent && (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={notify}
                onChange={(e) => setNotify(e.target.checked)}
              />
            }
            label="I would like to get notified when adbinder launches."
            labelPlacement="end"
            value={notify}
          />
        )}
        {!sent && <div style={{ height: 16 }}></div>}
        {!sent && (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={newsletter}
                onChange={(e) => setNewsletter(e.target.checked)}
              />
            }
            label="I would like to subscribe to adbinders email newsletter."
            labelPlacement="end"
            value={newsletter}
          />
        )}
        {!sent && <div style={{ height: 16 }}></div>}

        {!sent && (
          <DefaultButton
            text="Submit form"
            onClick={() => {
              console.log("submitting form");
              saveInFirebase(beta, notify, newsletter, name, email, company);
              setBeta(false);
              setNotify(false);
              setNewsletter(false);
              setName("");
              setEmail("");
              setCompany("");
              setSent(true);
            }}
          />
        )}

        {sent && <h4 style={{ marginTop: 32 }}> Registration sent </h4>}
      </Grid>
    </div>
  );
}
