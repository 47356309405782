import "./App.css";
import Copyright from "./screens/copyright";
import Footer from "./screens/footer";

import Nav from "./screens/nav";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Grid } from "@material-ui/core";

import SelectPlan from "./pages/SelectPlan";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import ComingSoonOne from "./pages/ComingSoonOne";
import ComingSoonTwo from "./pages/ComingSoonTwo";
import ComingSoonThree from "./pages/ComingSoonThree";
import ComingSoonFour from "./pages/ComingSoonFour";

import { useEffect } from "react";
import { auth, db, analytics } from "./firebase/firebaseConfig";

function App() {
  useEffect(() => {
    analytics.logEvent("landed");

    auth
      .signInAnonymously()
      .then((x) => {
        db.collection("landingData")
          .doc(x.user?.uid)
          .set(
            {
              landed: true,
              dateNow: Date.now(),
              dateNowLocale: new Date().toLocaleString("en-DK"),
              anymousUserUid: x.user?.uid,
            },
            { merge: true }
          );
      })
      .catch((x) => {
        db.collection("landingData").add({
          landed: true,
          dateNow: Date.now(),
          dateNowLocale: new Date().toLocaleString("en-DK"),
          anymousUserUid: "ERROR HAPPENED",
          error: x.toString(),
        });
      });
  }, []);
  return (
    <div className="App" id="home">
      <Grid
        container
        justifyContent="space-between"
        style={{ minHeight: "100vh" }}
      >
        <Grid item style={{ width: "100vw" }}>
          <Nav />
        </Grid>
        <Grid item xs={12}>
          <Router>
            <Switch>
              <Route path="/select-plan">
                <SelectPlan />
              </Route>
              <Route path="/coming-soon-1">
                <ComingSoonOne />
              </Route>

              <Route path="/coming-soon-2">
                <ComingSoonTwo />
              </Route>

              <Route path="/coming-soon-3">
                <ComingSoonThree />
              </Route>

              <Route path="/coming-soon-4">
                <ComingSoonFour />
              </Route>
              <Route path="/privacy-policy">
                <PrivacyPolicy />
              </Route>
              <Route path="/terms-of-service">
                <TermsOfService />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Router>
        </Grid>
        <Grid item xs={12}>
          <Footer />
          <Copyright />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
