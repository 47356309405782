import { Grid, TextField } from "@material-ui/core";
import { useState } from "react";
import Bjorn from "../assets/misc/bjornskalkam.jpeg";
import DefaultButton from "../components/defaultbutton";
import TextButton from "../components/textbutton";

import { auth, db } from "../firebase/firebaseConfig";

function saveMessageInFirebase(name: string, email: string, msg: string) {
  auth
    .signInAnonymously()
    .then((x) => {
      db.collection("landingMessages").add({
        message: msg,
        name: name,
        email: email,
        dateNow: Date.now(),
        dateNowLocale: new Date().toLocaleString("en-DK"),
        anymousUserUid: x.user?.uid,
      });
    })
    .catch((x) => {
      db.collection("landingMessages").add({
        message: msg,
        name: name,
        email: email,
        dateNow: Date.now(),
        dateNowLocale: new Date().toLocaleString("en-DK"),
        anymousUserUid: "ERROR HAPPENED",
        error: x.toString(),
      });
    });
}

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [sent, setSent] = useState(false);

  return (
    <div
      style={{
        width: "70vw",
        margin: "auto",
        backgroundColor: "#f9f9f9",
        borderRadius: 16,
        padding: 32,
      }}
    >
      <div style={{ height: 32 }}></div>
      <h1 style={{ fontSize: "1.6rem" }}>REACH OUT</h1>
      <div style={{ height: 32 }}></div>
      <Grid container style={{ width: "95%", margin: "auto" }} spacing={4}>
        {!sent && (
          <Grid item md={3} sm={12}>
            <Grid item>
              <img
                src={Bjorn}
                alt="Bjørn Skalkam"
                style={{
                  textAlign: "left",
                  height: 128,
                  width: 128,
                  objectFit: "cover",
                  borderRadius: 999,
                }}
              />
              <div style={{ height: 16 }}></div>
              <h4 style={{ fontSize: 16, fontWeight: "bold" }}>
                BJØRN SKALKAM
              </h4>
              <div style={{ height: 16 }}></div>
              <p>+45 23256191</p>
              <p>bjorn@adbinder.com</p>
            </Grid>
          </Grid>
        )}
        {!sent && <Grid item md={1}></Grid>}
        {!sent && (
          <Grid item md={8} sm={12}>
            <Grid container spacing={5}>
              <Grid item sm={12} md={6}>
                <TextField
                  label="Full Name"
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <TextField
                  label="Email"
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Grid>
              <div style={{ height: 128 }}></div>
            </Grid>
            <TextField
              label="Message"
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              multiline={true}
              rows={5}
              value={msg}
              onChange={(e) => {
                setMsg(e.target.value);
              }}
            />
            <div style={{ height: 32 }}></div>
          </Grid>
        )}
        <div style={{ textAlign: "center", width: "100%" }}>
          {!sent ? (
            <DefaultButton
              text="SEND MESSAGE"
              onClick={() => {
                saveMessageInFirebase(name, email, msg);
                setName("");
                setEmail("");
                setMsg("");
                setSent(true);
              }}
            />
          ) : (
            <div>
              <h1 style={{ fontSize: 16, color: "darkgreen" }}>
                THANK YOU FOR YOUR MESSAGE. WE WILL RESPOND WITHIN 48 HOURS.
              </h1>
              <div style={{ height: 16 }}></div>
              <TextButton
                text="SEND ANOTHER?"
                onClick={() => {
                  setSent(false);
                }}
              />
            </div>
          )}
        </div>
      </Grid>
      {!sent && <div style={{ height: 32 }}></div>}
      {sent && <div style={{ height: 16 }}></div>}
    </div>
  );
}

export default Contact;
