import { Grid, Link } from "@material-ui/core";
import DefaultButton from "../components/defaultbutton";

export default function SelectPlan() {
  return (
    <div style={{backgroundColor: '#f9f9f9'}}>
      <div>
        <div style={{ height: 64 }}></div>
        <h1 style={{ fontSize: "1.6rem" }}>SELECT YOUR PLAN</h1>
        <div style={{ height: 16 }}></div>
        <h1 style={{ fontSize: "1rem", color: "#696969" }}>
          ALL PLANS COME WITH A FREE 30-DAY TRIAL
        </h1>
        <div style={{ height: 32 }}></div>
        
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        style={{
          width: "80vw",
          marginLeft: "10vw",
        }}
      >
        <Grid md={3} sm={12} item style={{
              backgroundColor: "#fff",
              padding: 24,
              height: 450,
              width: "100%",
              borderRadius: 16,
              margin: 16
            }}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{
              height: 400}}
          >
            <Grid item>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "left",
                  color: "#83eaf1",
                }}
              >
                STARTUP
              </p>
              <div style={{ height: 16 }}></div>
              <p
                style={{
                  fontWeight: 400,
                  color: "#bebebe",
                  fontSize: "1rem",
                  textAlign: "left",
                }}
              > Up to 2 seats
                <br />
                <br />
                Up to 20 ads/month
                <br />
                <br />

                Role-based access management
              </p>
            </Grid>

            <Grid item>
            <Link
                  href="https://adbinder.com/coming-soon-1/"
                  style={{ textDecoration: "none" }}
                >
                  <DefaultButton text="FREE" onClick={() => null} />
                </Link>
              <div style={{ height: 16 }}></div>
              {/*<p
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                $49/MONTH
              </p>*/}
            </Grid>
          </Grid>
        </Grid>
        <Grid md={3} sm={12} item style={{
              backgroundColor: "#fff",
              padding: 24,
              height: 450,
              width: "100%",
              borderRadius: 16,
              margin: 16
            }}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{
              height: 400}}
          >
            <Grid item>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "left",
                  color: "#469bfe",
                }}
              >
                SCALEUP
              </p>
              <div style={{ height: 16 }}></div>
              <p
                style={{
                  fontWeight: 400,
                  color: "#bebebe",
                  fontSize: "1rem",
                  textAlign: "left",
                }}
              >
                free 30-day trial
                <br />
                <br />
                Up to 8 seats
                <br />
                <br />
                Up to 50 ads/month
                <br />
                <br />
                Role-based access management
                <br />
                <br />
                Manage several organizations
              </p>
            </Grid>
            <Grid item>
            <Link
                  href="https://adbinder.com/coming-soon-2/"
                  style={{ textDecoration: "none" }}
                >
                  <DefaultButton text="$199/MONTH" onClick={() => null} />
                </Link>
              <div style={{ height: 16 }}></div>
              {/*<p
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                $199/MONTH
              </p>*/}
            </Grid>
          </Grid>
        </Grid>
        <Grid md={3} sm={12} item style={{
              backgroundColor: "#fff",
              padding: 24,
              height: 450,
              width: "100%",
              borderRadius: 16,
              margin: 16
            }}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{
              height: 400}}
          >
            <Grid item>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "left",
                  color: "#045de9",
                }}
              >
                PRO
              </p>
              <div style={{ height: 16 }}></div>
              <p
                style={{
                  fontWeight: 400,
                  color: "#bebebe",
                  fontSize: "1rem",
                  textAlign: "left",
                }}
              >
                free 30-day trial
                <br />
                <br />
                Up to 20 seats
                <br />
                <br />
                Up to 100 ads/month
                <br />
                <br />
                Role-based access management
                <br />
                <br />
                Manage several organizations
                <br />
                <br />
                24/7 support
              </p>
            </Grid>
            <Grid item>
            <Link
                  href="https://adbinder.com/coming-soon-3/"
                  style={{ textDecoration: "none" }}
                >
                  <DefaultButton text="$499/MONTH" onClick={() => null} />
                </Link>
              <div style={{ height: 16 }}></div>
              {/*<div style={{ height: 16 }}></div>
              <p
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                $499/MONTH
              </p>*/}
            </Grid>
          </Grid>
        </Grid>
      {/* <Grid md={3} sm={12} item style={{
              backgroundColor: "#fff",
              padding: 24,
              height: 450,
              width: "100%",
              borderRadius: 16,
              margin: 16
            }}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{
              height: 400}}
          >
            <Grid item>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "left",
                  color: "#293b8b",
                }}
              >
                ENTERPRISE
              </p>
              <div style={{ height: 16 }}></div>
              <p
                style={{
                  fontWeight: 400,
                  color: "#bebebe",
                  fontSize: "1rem",
                  textAlign: "left",
                }}
              >
                free 30-day trial
                <br />
                <br />
                Unlimited seats
                <br />
                <br />
                Unlimited ads/month
                <br />
                <br />
                Role-based access management
                <br />
                <br />
                Manage several organizations
                <br />
                <br />
                Dedicated customer success engineer
                <br />
                <br />
                24/7 support
              </p>
            </Grid>
            <Grid item>
              <Scroll.Link to="contact" spy={true} smooth={true} duration={500}>
                <Link style={{ cursor: "pointer", color: "#000" }}>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    CONTACT US
                  </p>
                </Link>
              </Scroll.Link>
            </Grid>
          </Grid>
        </Grid>*/}
      </Grid>
      </div>
      <div style={{ height: 64 }}></div>
    </div>
  );
}
