import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MouseEventHandler } from "react";

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(-45deg, #4a5eea 30%, #469bfe 90%)",
    border: 0,
    borderRadius: 50,
    color: "white",
    height: 42,
    padding: "0 20px",
  },
});

type Props = {
  onClick: MouseEventHandler;
  text: string;
};

const DefaultButton = ({ onClick, text }: Props) => (
  <Button
    color="primary"
    variant="contained"
    className={useStyles().root}
    onClick={onClick}
  >
    <span
      style={{
        textDecoration: "none",
      }}
    >
      {text}
    </span>
  </Button>
);

export default DefaultButton;
