import React from "react";

import Grid from "@material-ui/core/Grid";
import TextButton from "../components/textbutton";
import StartTrialButton from "../components/starttrialbutton";
import Dashboard from "../assets/Dashboard@2x.jpg";

import * as Scroll from "react-scroll";

const Hero = () => (
  <div style={{ maxWidth: "80vw", marginLeft: "10vw" }}>
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      spacing={5}
      style={{
        paddingTop: 32,
        paddingBottom: 96,
        //paddingLeft: 64,
        //paddingRight: 64,
      }}
    >
      <Grid item sm={12} md={5}>
        <div style={{ textAlign: "left" }}>
          {/*<h1>Streamline your online advertisements</h1>*/}
          <h1>A/B test your advertisement platforms</h1>
          <div style={{ height: 16 }}></div>
          <h5>
            Launch your online advertisements on 8 different advertising
            platforms and control your spend based on real data.
          </h5>

          <div style={{ height: 16 }}></div>
          <Grid container direction="row">
            <StartTrialButton />
            <div style={{ width: 8 }}></div>

            <Scroll.Link to="pricing" spy={true} smooth={true} duration={500}>
              <TextButton text="View plans" onClick={() => null} />
            </Scroll.Link>
          </Grid>
          <div style={{ height: 32 }}></div>
        </div>
      </Grid>

      <Grid item sm={12} md={7}>
        <img
          alt="iPad app"
          src={Dashboard}
          style={{
            maxWidth: "100%",
            maxHeight: "57vh",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            boxShadow: "rgb(0 0 0 / 10%) 0px 0px 6px 1px",
            //boxShadow: "0 0 1vw 0 rgb(15 15 15 / 22%)",
          }}
        ></img>
      </Grid>
    </Grid>
  </div>
);

export default Hero;

/*

Thank you for your interest.

We are currently putting the finishing touches on Adbinder, and are expecting to launch in Q4 of 2020.

In the meantime, we would really appreciate if you could spend 30 seconds on giving us some feedback on the concept.

1. Would you like to be notified when we launch? YES / NO

2. Would you like to subscribe to our newsletter? YES / NO

3. EXTRA hvis man trykker ja på 1 eller 2: email felt ___________

4. OPTIONAL: How much do you think adbinder should cost? ___________

5. OPTIONAL: Any other comments? ___________

*/
