import { Button } from "@material-ui/core";
import React, { MouseEventHandler } from "react";

type Props = {
  onClick: MouseEventHandler;
  text: string;
};

const TextButton = ({ onClick, text }: Props) => (
  <Button onClick={onClick}>{text}</Button>
);

export default TextButton;
