function Copyright() {
  return (
    <div
      style={{
        backgroundColor: "#000",
        color: "#fff",
        paddingBottom: 8,
        paddingTop: 8,
      }}
    >
      <p style={{ fontSize: "1rem" }}>© www.adbinder.com 2021</p>
    </div>
  );
}

export default Copyright;
