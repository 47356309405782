import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/app";
import "firebase/analytics";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyCStpO-aJPCzWfSPsExMWBhTbuFXAKZFXk",
  authDomain: "adbinder.firebaseapp.com",
  projectId: "adbinder",
  storageBucket: "adbinder.appspot.com",
  messagingSenderId: "409540199830",
  appId: "1:409540199830:web:cdf2afe8fce8d3c32349b7",
  measurementId: "G-CC6V1EK5F9",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const db = firebase.firestore();
export const analytics = firebase.analytics();
export const auth = firebase.auth();
