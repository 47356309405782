import { Grid } from "@material-ui/core";
import TikTokLogo from "../assets/services/tiktok.png";
import SnapchatLogo from "../assets/services/snapchat.png";
import GoogleLogo from "../assets/services/new_google.png";
import FacebookLogo from "../assets/services/facebook.png";
import PinterestLogo from "../assets/services/pinterest.png";
import BingAdsLogo from "../assets/services/bingads.png";
import LinkedInLogo from "../assets/services/linkedin.png";
import AmazonLogo from "../assets/services/amazon.png";
import { useEffect, useState } from "react";

function Services() {
  const [_maxWidth, setMaxWidth] = useState("120px");
  const [mobile, setMobile] = useState(false);

  function handleResize() {
    if (window.innerWidth < 600) setMobile(true);
    else setMobile(false);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (mobile) setMaxWidth("20vw");
    else setMaxWidth("120px");
  }, [mobile]);

  return (
    <div style={{ backgroundColor: "#fafafa" }}>
      <div style={{ height: 32 }}></div>
      <h4 style={{ color: "#4a5eea", fontSize: "1.3rem" }}>
        supported by adbinder
      </h4>
      <Grid
        container
        direction="row"
        spacing={0}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          <Grid
            container
            direction="row"
            spacing={0}
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item>
              <img
                src={GoogleLogo}
                alt="Google ads logo"
                style={{ maxWidth: _maxWidth, maxHeight: 60 }}
              ></img>
            </Grid>
            <Grid item>
              <img
                src={TikTokLogo}
                alt="Tiktok logo"
                style={{ maxWidth: _maxWidth, maxHeight: 60 }}
              ></img>
            </Grid>
            <Grid item>
              <img
                src={SnapchatLogo}
                alt="Snapchat logo"
                style={{ maxWidth: _maxWidth, maxHeight: 60 }}
              ></img>
            </Grid>

            <Grid item>
              <img
                src={FacebookLogo}
                alt="Facebook for business logo"
                style={{ maxWidth: _maxWidth, maxHeight: 60 }}
              ></img>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          <Grid
            container
            direction="row"
            spacing={0}
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item>
              <img
                src={PinterestLogo}
                alt="Pinterest logo"
                style={{ maxWidth: _maxWidth, maxHeight: 60 }}
              ></img>
            </Grid>
            <Grid item>
              <img
                src={BingAdsLogo}
                alt="Bing ads logo"
                style={{ maxWidth: _maxWidth, maxHeight: 60 }}
              ></img>
            </Grid>
            <Grid item>
              <img
                src={LinkedInLogo}
                alt="LinkedIn logo"
                style={{ maxWidth: _maxWidth, maxHeight: 60 }}
              ></img>
            </Grid>
            <Grid item>
              <img
                src={AmazonLogo}
                alt="Amazon logo"
                style={{ maxWidth: _maxWidth, maxHeight: 60 }}
              ></img>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Services;
