import { Grid, Typography } from "@material-ui/core";
import Synchronize from "../assets/icons/icons8-synchronize.svg";
import Ok from "../assets/icons/icons8-ok.svg";
import Services from "../assets/icons/icons8-services.svg";

function YourData() {
  return (
    <div style={{ maxWidth: "60vw", marginLeft: "20vw", textAlign: "center" }}>
      <h1 style={{ fontSize: "1.6rem" }}>YOUR DATA IS SAFE</h1>
      <div style={{ height: 48 }}></div>

      <Grid container direction="row" alignItems="flex-start" spacing={10}>
        {/*<Grid item md={3}>
          <Grid container wrap="nowrap" spacing={0}>
            <Grid item xs zeroMinWidth>
              <Typography
                style={{
                  overflowWrap: "break-word",
                  fontSize: "1.2rem",
                  color: "#696969",
                  fontWeight: "bold",
                  maxWidth: "85%",
                  textAlign: "left",
                }}
              >
                PRIVACY BY DESIGN
              </Typography>
            </Grid>
            <Grid item>
              <img src={Key} alt="icon" style={{ width: 48, height: 48 }}></img>
            </Grid>
          </Grid>
          <div style={{ height: 8 }}></div>
          <p style={{ textAlign: "justify" }}>
            adbinder is designed with privacy in mind from the first line of
            code. We ask for as little information as possible and store as
            little as we can in our cloud.
          </p>
              </Grid>*/}

        <Grid item md={12} lg={4}>
          <Grid container wrap="nowrap" spacing={0}>
            <Grid item xs zeroMinWidth>
              <Typography
                style={{
                  overflowWrap: "break-word",
                  fontSize: "1.2rem",
                  color: "#696969",
                  fontWeight: "bold",
                  maxWidth: "85%",
                  textAlign: "left",
                }}
              >
                INDUSTRY STANDARDS
              </Typography>
            </Grid>
            <Grid item>
              <img
                src={Synchronize}
                alt="icon"
                style={{ width: 48, height: 48 }}
              ></img>
            </Grid>
          </Grid>
          <div style={{ height: 8 }}></div>
          <p style={{ textAlign: "justify" }}>
            Adbinder leverages state-of-the-art integrations through OAuth 2.0
            and OpenID Connect. This means you have full control over which
            resources adbinder can access.
          </p>
        </Grid>

        <Grid item md={12} lg={4}>
          <Grid container wrap="nowrap" spacing={0}>
            <Grid item xs zeroMinWidth>
              <Typography
                style={{
                  overflowWrap: "break-word",
                  fontSize: "1.2rem",
                  color: "#696969",
                  fontWeight: "bold",
                  maxWidth: "85%",
                  textAlign: "left",
                }}
              >
                ACCESS MANAGEMENT
              </Typography>
            </Grid>
            <Grid item>
              <img src={Ok} alt="icon" style={{ width: 48, height: 48 }}></img>
            </Grid>
          </Grid>
          <div style={{ height: 8 }}></div>
          <p style={{ textAlign: "justify" }}>
            You control who in your organization can manage your financials, who
            can start and stop advertising campaigns, and who can only visit the
            dashboard and analytics.
          </p>
        </Grid>
        <Grid item md={12} lg={4}>
          <Grid container wrap="nowrap" spacing={0}>
            <Grid item xs zeroMinWidth>
              <Typography
                style={{
                  overflowWrap: "break-word",
                  fontSize: "1.2rem",
                  color: "#696969",
                  fontWeight: "bold",
                  maxWidth: "85%",
                  textAlign: "left",
                }}
              >
                SECURITY COMPLIANCE
              </Typography>
            </Grid>
            <Grid item>
              <img
                src={Services}
                alt="icon"
                style={{ width: 48, height: 48 }}
              ></img>
            </Grid>
          </Grid>
          <div style={{ height: 8 }}></div>
          <p style={{ textAlign: "justify" }}>
            adbinder leverages Google Cloud, and we are compliant with all major
            security standards, including ISO 27001, ISO 27017, ISO 27018, SOC
            1, SOC 2, and SOC 3
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

export default YourData;
