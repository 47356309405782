import { Grid, Link, Modal } from "@material-ui/core";
import StartTrialButton from "../components/starttrialbutton";
import AdbinderLogo from "../assets/logo/logo_128.png";
import * as Scroll from "react-scroll";
import { useEffect, useState } from "react";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

function Nav() {
  let isRoot = window.location.pathname === "/"; //Equals true if we're at the root
  
  const [mobile, setMobile] = useState(false);

  function handleResize() {
    if (window.innerWidth < 700) setMobile(true);
    else setMobile(false);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ maxWidth: "90vw", marginLeft: "5vw" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={5}
        style={{
          //paddingLeft: 64,
          //paddingRight: 64,
          paddingTop: 32,
          paddingBottom: 32,
        }}
      >
        <Grid item onClick={() => null} style={{ cursor: "pointer" }}>
          <Link
            href="https://adbinder.com"
            style={{
              textDecoration: "none",
            }}
          >
            <Grid container direction="row" alignItems="center">
              <img
                src={AdbinderLogo}
                alt="tiktok logo"
                style={{ height: 38, width: 38 }}
              ></img>
              <div style={{ width: 8 }}></div>
              <h1
                style={{
                  fontSize: "1.6rem",
                  color: "#000",
                }}
              >
                adbinder
              </h1>
            </Grid>
          </Link>
        </Grid>
        <div style={{marginRight: 16}}>

        {mobile ? <Link onClick={() => {
          handleOpen();
          console.log('asd');
        }}><MenuIcon /></Link>: null}</div>    

        <Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="simple-modal-title"
  aria-describedby="simple-modal-description"
> 
<div style={{ outline: 'none', color: "#fff",  textAlign: 'right'}}>

  <Link style={{textDecoration: 'none', color: '#fff', cursor: 'pointer'}} onClick={handleClose}>
<CloseIcon style={{fontSize: '6vh', padding: 16}}/>
</Link>

  <Grid container direction="column" style={{height: '80vh', width:'100vw', padding: '10vh'}} alignItems="center" justifyContent="space-around">
 
  <Grid item>
  <Link style={{textDecoration: 'none', color: '#fff', cursor: 'pointer'}} href="https://adbinder.com#home">
  <h4>
  Home</h4>
  </Link>
  </Grid>
  <Grid item>
  <Link style={{textDecoration: 'none', color: '#fff', cursor: 'pointer'}} href="https://adbinder.com#pricing">
  <h4>
  Pricing
  </h4>
  </Link>
  </Grid>
  <Grid item>
  <Link style={{textDecoration: 'none', color: '#fff', cursor: 'pointer'}} href="https://adbinder.com#contact">
  <h4>
  Contact
  </h4>
  </Link>
  </Grid>

  <Grid item>
  <StartTrialButton/>
  </Grid>
  <Grid item></Grid>
  </Grid>
  </div>
</Modal>    

        {isRoot && !mobile ? (
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Scroll.Link to="home" spy={true} smooth={true} duration={500}>
                <Link
                  style={{
                    fontSize: "1.1rem",
                    cursor: "pointer",
                    color: "#000",
                  }}
                  onClick={() => null}
                >
                  Home
                </Link>
              </Scroll.Link>
              <div style={{ width: 32 }}></div>

              <Scroll.Link to="pricing" spy={true} smooth={true} duration={500}>
                <Link
                  style={{
                    fontSize: "1.1rem",
                    cursor: "pointer",
                    color: "#000",
                  }}
                  onClick={() => null}
                >
                  Pricing
                </Link>
              </Scroll.Link>
              {/*<div style={{ width: 32 }}></div>
            <Link
              style={{ fontSize: "1.1rem", cursor: "pointer", color: "#000" }}
              onClick={() => goToTop()}
            >
              Team
            </Link>*/}
              <div style={{ width: 32 }}></div>
              <Scroll.Link to="contact" spy={true} smooth={true} duration={500}>
                <Link
                  style={{
                    fontSize: "1.1rem",
                    cursor: "pointer",
                    color: "#000",
                  }}
                  onClick={() => null}
                >
                  Contact
                </Link>
              </Scroll.Link>
              <div style={{ width: 32 }}></div>
              <StartTrialButton />
            </Grid>
          </Grid>
        ) : !isRoot && !mobile ? (
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Link
                href="https://adbinder.com#home"
                style={{
                  fontSize: "1.1rem",
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => null}
              >
                Home
              </Link>
              <div style={{ width: 32 }}></div>

              <Link
                href="https://adbinder.com#pricing"
                style={{
                  fontSize: "1.1rem",
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => null}
              >
                Pricing
              </Link>
              {/*<div style={{ width: 32 }}></div>
            <Link
              style={{ fontSize: "1.1rem", cursor: "pointer", color: "#000" }}
              onClick={() => goToTop()}
            >
              Team
            </Link>*/}
              <div style={{ width: 32 }}></div>

              <Link
                href="https://adbinder.com#contact"
                style={{
                  fontSize: "1.1rem",
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => null}
              >
                Contact
              </Link>
              <div style={{ width: 32 }}></div>
              <StartTrialButton />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

export default Nav;
